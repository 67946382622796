import * as React from "react"
import { CheckCircleIcon, XIcon, ExclamationCircleIcon } from '@heroicons/react/outline'

export default function Alert(props: React.PropsWithChildren<{ success: Boolean, message: String, onDismiss: () => void }>) {
    return (
        <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className={`p-2 rounded-lg ${props.success ? 'bg-green-600' : 'bg-red-600'} shadow-lg sm:p-3`}>
                    <div className="flex items-center justify-between flex-wrap">
                        <div className="w-0 flex-1 flex items-center">
                            {
                                props.success ?
                                    <span className={`flex p-2 rounded-lg ${props.success ? 'bg-green-800' : 'bg-red-800'}`}>
                                        <CheckCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </span>
                                    :
                                    <span className={`flex p-2 rounded-lg ${props.success ? 'bg-green-800' : 'bg-red-800'}`}>
                                        <ExclamationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </span>
                            }
                            <p className="ml-3 font-medium text-white truncate">
                                <span className="inline">{props.message}</span>
                            </p>
                        </div>
                        <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                            <button
                                type="button"
                                className={`-mr-1 flex p-2 rounded-md hover:bg-color-500 focus:outline-none focus:ring-2 focus:ring-white`}
                                onClick={props.onDismiss}
                            >
                                <span className="sr-only">Dismiss</span>
                                <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
